import { cloneDeep } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import EnhancedFrom, {
  ImperativeHandle,
} from "../../components/form/EnhancedFrom";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardHeader } from "../../components/ui/card";
import {
  useAddPageMutation,
  usePageQuery,
  useUpdatePageMutation,
} from "../../services/pageCreateApi";
import {
  useOrderQuery,
  useChangeProdAvailMutation,
  useUpdateOrderMutation,
  useSendStatusMailMutation,
} from "../../services/orderApi";

import { useStatusesQuery } from "../../services/statusesApi";
import { serviceUrl, serviceUrlFile } from "../../config";
import { UiSelect } from "../../components/ui/UiSelect";
type OrderProps = {};

const Order = (props: OrderProps) => {
  const params = useParams();

  const { data } = useOrderQuery(params.id);
  const [dataCopy, setDataCopy] = React.useState(data);

  React.useEffect(() => {
    setDataCopy(data);
  }, [data]);
  const { data: statuses } = useStatusesQuery(null);
  const [changeProdAvail] = useChangeProdAvailMutation();
  const [updateOrder] = useUpdateOrderMutation();
  const [sendStatusMail] = useSendStatusMailMutation();

  const save = () => {};

  const formRef = React.useRef<ImperativeHandle>(null);

  const dict = {
    name: "imię i nazwisko",
    street: "ulica",
    zip: "kod pocztowy",
    email: "email",
    nip: "nip",
    phone: "telefon",
    deliveryInpostAddress: "kod inpost'u",
    deliveryCost: "koszt dostawy",
    dataZamowienia: "data zamowienia",
    deliveryType: "typ dostawy",
    getTotalServices: "suma",
    packingCost: "koszt pakowania",
    totalProducts: "suma produktów",
    pymentType: "typ płatności",
    zaplacono: "zapłacono",
    city: "miasto",
  };

  const dostepnoscList = [
    { value: "now", label: "natychmiast" },
    { value: "twodays", label: "2-3 dni rob" },
    { value: "more", label: ">3 dni" },
    { value: "bigmore", label: ">7 dni" },
  ];

  const changeAvail = (id: any, value: any) => {
    changeProdAvail({ id: id, value: value });
  };

  const countCuponValue = () => {
    let value = 0;
    if (dataCopy?.cupon?.type == "procent") {
      value = (dataCopy?.totalProducts * dataCopy?.cupon?.discount) / 100;
    } else {
      value = dataCopy?.cupon?.discount;
    }
    return value;
  };

  return (
    <Card>
      <CardContent>
        <CardHeader>Zamówienie {dataCopy?.order_id}</CardHeader>

        <strong>Podsumowanie</strong>
        <div className="grid grid-cols-2">
          <div className="py-5">
            <div className="flex gap-5">
              <div>data zamówienia:</div>
              <div>
                {dataCopy?.dataZamowienia["$date"] &&
                  new Date(
                    dataCopy?.dataZamowienia["$date"]
                  ).toLocaleDateString()}
              </div>
            </div>

            {[
              "deliveryCost",
              "packingCost",
              "totalProducts",
              "getTotalServices",
            ].map((key) => {
              return (
                <div className="flex gap-5">
                  <div>{dict[key]}</div>
                  <div>{dataCopy?.[key]}</div>
                </div>
              );
            })}
            {dataCopy?.cupon?.code && (
              <div>
                Kupon: {dataCopy?.cupon?.code} - {countCuponValue()} zł
              </div>
            )}
          </div>
          <div>
            <div className="flex items-end gap-2 max-w-[200px]">
              <UiSelect
                label="status"
                options={statuses}
                value={dataCopy?.status}
                onChange={(value) => {
                  setDataCopy({ ...dataCopy, status: value });
                }}
              />
              <Button
                className="min-w-[100px] "
                onClick={() => {
                  updateOrder({ id: dataCopy?._id["$oid"], rest: dataCopy });
                }}
              >
                Zapisz
              </Button>
              <Button
                className="min-w-[140px] "
                onClick={(val) => {
                  sendStatusMail(dataCopy?._id["$oid"]);
                }}
              >
                Wyślij email
              </Button>
            </div>
          </div>
        </div>

        {dataCopy?.deliveryInpostAddress && (
          <div>
            <div>Dostawa INPOST</div>
            <div>{dataCopy?.deliveryInpostAddress}</div>
          </div>
        )}
        <hr className="my-5 block" />
        <strong className="py-5 block">Dane klienta</strong>
        <div className="grid md:grid-cols-2">
          <div className="grid gap-2">
            Dane do FV
            <div>
              {dataCopy?.address &&
                Object.keys(dataCopy?.address).map((key) => {
                  return (
                    <div className="flex gap-5">
                      <div>{dict[key]}</div>
                      <div>{dataCopy?.address[key]}</div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="grid gap-2">
            Dane dostawy
            <div>
              {dataCopy?.addressDelivery &&
                Object.keys(dataCopy?.addressDelivery).map((key) => {
                  return (
                    <div className="flex gap-5">
                      <div>{dict[key]}</div>
                      <div>{dataCopy?.addressDelivery[key]}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <hr className="my-5 block" />
        <div>
          {" "}
          <CardHeader>Produkty</CardHeader>
        </div>

        <div>
          {dataCopy?.products &&
            dataCopy?.products.map((productx: any) => {
              let product = cloneDeep(productx);
              if (product?.productSlug.includes("konfigurator")) {
                product.product_name =
                  product.configurator.sznurek.name +
                  " " +
                  product.configurator.zawieszka.name;
                if (product.configurator.zawieszka2) {
                  product.product_name +=
                    " " + product.configurator.zawieszka2.name;
                }
              }
              return (
                <div className="flex gap-5 my-5">
                  <div className="max-h-[200px] max-w-[200px]">
                    {!product?.productSlug.includes("konfigurator") && (
                      <img
                        key={Date.now()}
                        src={`${serviceUrlFile}${product?.image?._id}?w=300&h=300&ext=webp&q=60`}
                        className="w-full h-full object-cover rounded-sm"
                      />
                    )}
                    <div className="grid grid-cols-2 gap-2">
                      {product?.productSlug.includes("konfigurator") &&
                        Object.values(product?.configurator).map(
                          (option: any) => {
                            return (
                              <img
                                key={Date.now()}
                                src={`${serviceUrlFile}${option.image?._id}?w=300&h=300&ext=webp&q=60`}
                                className="w-full h-full object-cover rounded-sm"
                              />
                            );
                          }
                        )}
                    </div>
                  </div>
                  <div className="flex gap-10">
                    <div>
                      <div>{product.product_name}</div>
                      <div>Cena sprzedaży: {product.price}</div>
                      <div>Cena przed promocją: {product.price_original}</div>
                      <div>Cena promocyjna: {product.sale_price}</div>
                      <div>Ilość: {product.qty}</div>

                      {!product?.productSlug.includes("konfigurator") && (
                        <div className="flex items-center gap-2">
                          Zmień dostępność:
                          <UiSelect
                            options={dostepnoscList}
                            value={product.send_time}
                            onChange={(value) => {
                              changeAvail(product.id, value);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {product?.configurator && (
                      <div>
                        Elementy:
                        <div>
                          {Object.keys(product?.configurator).map(
                            (option: any) => {
                              return (
                                <div>
                                  {product?.configurator[option].name}{" "}
                                  {product?.configurator[option].addPrice}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                    {product?.options && (
                      <div>
                        Opcje:
                        <div>
                          {product?.options?.map((option: any) => {
                            return (
                              <div>
                                {option.name}: {option.value}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </CardContent>
      {/* <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          style={{ marginRight: "8px", marginTop: "2em" }}
          onClick={() => {
            save();
          }}
        >
          zapisz
        </Button>
      </div> */}
    </Card>
  );
};
export default Order;
